<template>
    <div class="modal fade" id="modalItemDetail" tabindex="-1" aria-labelledby="modal" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content modal-card" v-if="!loading">
                <div class="modal-header border-0">
                    <button type="button" class="rounded-full border-0 w-10 h-10 flex items-center justify-center bg-brownCustom"
                        @click="this.$router.push('/marketplace')" data-bs-dismiss="modal" aria-label="Close"><i
                            class="fa-solid fa-xmark text-white "></i></button>
                </div>
                <div class="modal-body md:px-10 py-20 -mt-5">
                    <div class="grid grid-cols-12">
                        <div class="md:col-span-7 col-span-12 grid">
                            <img :src="item.image" class="mx-auto mt-20">
                        </div>
                        <div class="card md:mt-0 mt-10 rounded-2xl shadows bg-greyTransparent2 md:col-span-5 col-span-12">
                            <div class="card-body">
                                <div class="flex">
                                    <div class="font-bold">
                                        <span class="text-3xl">{{ item.name }}</span><br>
                                    </div>
                                    <div class="ml-auto">
                                    </div>
                                </div>
                                <div class="flex md:items-center mt-5 mb-3">
                                    <div>
                                        <label class="block font-bold md:text-right mb-1 md:mb-0 pr-4"
                                            for="inline-full-name">
                                            <img class="md:w-11 w-10" src="/images/owner.svg">
                                        </label>
                                    </div>
                                    <div class="w-full">
                                        <input
                                            class="bg-greyCS2 appearance-none border-0 rounded w-full py-2 px-4 text-white leading-tight"
                                            id="inline-full-name" disabled type="text"
                                            :value="listing.seller">
                                    </div>
                                </div>
                                <div>
                                    <label class="w-100">Price Per Item</label>
                                    <input disabled @keypress="this.$root.onlyNumber"
                                           class="bg-greyCS2 appearance-none rounded w-full py-2 px-4 text-white leading-tight" :value="user.web3.utils.fromWei(listing.pricePerItem)" />
                                </div>
                                <div class="mt-4">
                                    <label class="w-100">Buy Amount <span class="float-right">Remaining : {{ listing.remainingAmount}}</span></label>
                                    <input @keypress="this.$root.onlyNumber" class="bg-greyCS2 appearance-none rounded w-full py-2 px-4 text-white leading-tight" v-model="buyAmount" />
                                </div>
                                <div class="mt-5">Description Items</div>
                                <div class="grid md:grid-cols-1 gap-2 mt-2">
                                    <p class="bg-greyCS2 p-2 rounded-xl text-center py-4">{{ item.description }}</p>
                                </div>
                                <div class="grid grid-cols-2 mt-4">
                                    <div class="md:mx-0 mx-auto flex items-center">
                                        <span class="font-bold">Cost : {{ parseFloat(user.web3.utils.fromWei(listing.pricePerItem)) * buyAmount }} FNF</span>
                                    </div>
                                    <div class="grid">
                                        <button
                                            class="md:ml-4 shadow bg-greenCustom py-2 px-10 rounded-lg border-0 text-white font-bold float-right" v-if="!approved"  @click="approve">Approve</button>
                                        <button
                                            class="md:ml-4 shadow bg-greenCustom py-2 px-10 rounded-lg border-0 text-white font-bold float-right" v-if="approved"  @click="buy">BUY</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .modal-card {
        @apply rounded-xl bg-blackCustom;
        border: 3px solid #FFBE2A;
    }

    /* Customize the label (the container) */
    .containers {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .containers input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

    }

    .checkmark {
        border-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #3A3838;
        border: 2px solid white;
    }

    /* On mouse-over, add a grey background color */
    .containers:hover input~.checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .containers input:checked~.checkmark {
        background-color: white;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .containers input:checked~.checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .containers .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
</style>
<script>
import {
    useUserStore
} from '@/stores/user'
    export default {
       props: {
            listing_id: Number,
        },
        setup: () => {

            const user = useUserStore()
            return {
                user: user,

            }
        },
        data() {
            return {
                app: this.$parent,
                listing: null,
                item: null,
                loading: true,
                buyAmount: 0,
                approved: false,
            }
        },
        methods: {
            async getItemInfo() {
                this.item = (await this.user.api.get('/nft/item/' + this.listing.itemId)).data
                this.loading = false;
            },
            async getListingInfo() {
                console.log(this.user.marketplace_item)
                this.listing = await this.user.marketplace_item.methods.listing(this.listing_id).call()
                this.getItemInfo()
            },
            async buy() {
                this.user.marketplace_item.methods.buy(this.listing_id, this.buyAmount).send({from: this.user.address})
            },
            async approve() {
                let that = this
                let approve_amount = '115792089237316195423570985008687907853269984665640564039457584007913129639935'; //(2^256 - 1 )
                this.user.fnf.methods.approve(this.user.marketplace_item._address, approve_amount).send({from: this.user.address})
                .on('receipt', function () {
                    that.checkApproved()
                })
            },
            async checkApproved() {
                this.approved =  parseInt(await this.user.fnf.methods.allowance(this.user.address, this.user.marketplace_item._address).call()) > 0
            },
        },
        mounted() {
            var myModal = new window.bootstrap.Modal(document.getElementById('modalItemDetail'))
            myModal.show()
            this.getListingInfo()
            this.checkApproved()
        }
    }
</script>
