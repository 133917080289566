import { createRouter, createWebHistory } from 'vue-router'
import Marketplace from '../views/Marketplace.vue'
import Dashboard from '../views/Dashboard.vue'
import Profile from '../views/Profile.vue'
import Summon from '../views/Summon.vue'
import Swap from '../views/Bridge.vue'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/marketplace',
    name: 'Markeplace',
    component: Marketplace,
    children: [
      {
        path: 'character/:id',
        name: 'MarketplaceCharacterDetail',
        component: Marketplace
      },
      {
        path: 'item/:id',
        name: 'MarketplaceItemDetail',
        component: Marketplace
      },
    ],
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/summon',
    name: 'Summon',
    component: Summon
  },
  {
    path: '/swap',
    name: 'Swap',
    component: Swap
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
