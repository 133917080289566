<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link>
  </div> -->
<div>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid mx-md-5 px-md-5">
      <a class="navbar-brand" href="#"><img src="/images/logo.png" class="md:w-32 w-20"></a>
      <button class="navbar-toggler py-2" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <span><i style="color: white;" class="fas fa-bars" width="20"></i></span>
      </button>
      <div class="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
        <ul class="navbar-nav ms-md-3">
          <router-link to="/" class="nav-link">Dashboard</router-link>
          <router-link to="/marketplace" class="nav-link">Marketplace</router-link>
          <router-link to="/summon" class="nav-link">Summon</router-link>
        </ul>
        <ul class="navbar-nav mb-5 mb-md-0 mt-5 mt-md-0">
          <button class="btn btn-wallet" v-if="user.address == null" @click="connectMetamask">Connect to Wallet</button>
          <router-link to="/profile" class="btn btn-wallet text-dark" v-if="user.address != null">My Profile</router-link>
        </ul>
      </div>
    </div>
  </nav>
  <div class="bgPage pb-5" style="min-height: 100vh;">
  <router-view/>
  </div>
  </div>
</template>

<style>
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  background:black;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.img-loading{
  animation: pulse 2s infinite;
}
#app {
     font-family: 'Chakra Petch', sans-serif;
    font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white
}
.btn-wallet{
  background:#C9CA03;
  color:black;
  font-size: large;
  font-weight: bold;
  border-radius:30px;
}
.navbar{
    padding-top: 15px;
    padding-bottom: 30px;
    background-image: url('/images/bg_nav.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-size: 20px;
}
/* #nav {
  padding: 30px;
} */

.navbar a {
  font-weight: bold;
  color: white;
}
.navbar a:hover {
  font-weight: bold;
  color: grey;
}
.bgPage{
    /* background-image: url('/images/bg_wood.svg'); */
    @apply md:bg-greyCS1 bg-blackCustom;
    background-repeat: repeat;
  background-size: auto;
}
.navbar a.router-link-exact-active {
  color: #42b983;
}
</style>
<script>
import {useUserStore} from './stores/user'
export default {
      name: 'App',
  setup: () => {

    const user = useUserStore()
      return {
        user: user
      }
  },
  mounted() {

  },
  methods: {
    connectMetamask() {
      this.user.connect()
    },
    onlyNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
  }
}
</script>
