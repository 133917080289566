<template>
    <div v-if="saleDetail.length != 0" class="py-5">
        <div class="card mx-auto bg-greyTransparent w-11/12" style="border-radius: 20px;">
  <div class="card-body md:mx-10 mt-3 my-3">
                    <h1 class="text-blackCustom font-bold mb-5">Dashboard</h1>
 <div class="card mx-auto mt-5 rounded-xl bg-blackCustom2">
       <div class="card-body lg:mx-5">
           <div class="grid md:grid-cols-12 gap-y-5">
               <div class="md:col-span-4 d-inline-flex items-center">
                   <img src="images/totalCharacter.svg" class="w-1/4" alt="">
                      <div class="ms-3 mt-3">
                       TOTAL SALE CHARACTER <small>(24 hr)</small>
                       <h2>{{saleDetail["data"].totalCharacter}}</h2>
                       </div>
               </div>
               <div class="md:col-span-4 d-inline-flex items-center">
                   <img src="images/totalitem.svg" class="w-1/4" alt="">
                   <div class="ms-3 mt-3">
                       TOTAL SALE ITEM <small>(24 hr)</small>
                       <h2>{{saleDetail["data"].totalItem}}</h2>
                       </div>
               </div>
               <div class="md:col-span-4 d-inline-flex items-center">
                   <img src="images/totalFNF.svg" class="w-1/4" alt="">
                <div class="ms-3 mt-3">
                   TOTAL FNF <small>(24 hr)</small>
                   <h2>{{user.web3.utils.fromWei(saleDetail["data"].totalFNF.toString())}}</h2>
                       </div>
               </div>
           </div>
       </div>
       </div>
    <div class="row mt-5">
             <div class="col-md-6 mb-5">
               <h4 class="text-blackCustom fw-bold">Recently Listed</h4>
               <h6 class="text-blackCustom">Coming Soon</h6>
             </div>
             <div class="col-md-6">
               <h4 class="text-blackCustom fw-bold">Recently Sold</h4>
               <h6 class="text-blackCustom">Coming Soon</h6></div>
    </div>
  </div>
</div>
    </div>
</template>
<script>
import {
  useUserStore
} from '@/stores/user'

export default {
  name: 'Dashboard',
  components: {
    // HelloWorld
  },
  setup: () => {
    const user = useUserStore()
    return {
      user: user,
    }
  },
   data: () => ({
        filter: '24',
        saleDetail: [],
        load: false,
        }),
  methods: {
    async getDataSale() {
      this.saleDetail = await this.user.api.get(`/marketplace/sales`)
    },
  },
  mounted() {
        document.title = "Dashboard | FarmAndFight"
        let that = this
        this.getDataSale()
        this.$nextTick(() => {
          that.load = true
        })
  }
}
</script>
<style scoped>
.btn-cust{
  border-radius: 10px!important;
  font-size:1.5em;
}
.btn-active{
  background:#50E91A !important;
  color:#353333 !important;
}
.btn-non{
  background:#5E5E5E;
  color:white;
}
</style>
