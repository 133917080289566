<template>
    <div class="modal fade" id="modalFilter" tabindex="-1" aria-labelledby="modal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content card-filter">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Farming Items</h5>
                    <button type="button" class="bg-transparent border-0" data-bs-dismiss="modal" aria-label="Close"><i
                            class="fa-solid fa-xmark text-white"></i></button>
                </div>
                <div class="modal-body -mt-5">
                    <div class="text-xl">
                        <div class="grid grid-cols-2 mt-5">
                            <label class="containers">Ore
                                <input type="checkbox" value="ore" v-model="this.app.filterChecked.farmingItems">
                                <span class="checkmark"></span>
                            </label>
                            <label class="containers">Wood
                                <input type="checkbox" value="wood" v-model="this.app.filterChecked.farmingItems">
                                <span class="checkmark"></span>
                            </label>
                            <label class="containers">Food
                                <input type="checkbox" value="food" v-model="this.app.filterChecked.farmingItems">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="text-xl mt-5">
                        Rarity Characters
                        <div class="mt-5">
                            <label class="containers">PickAxe
                                <input type="checkbox" value="pickaxe" v-model="this.app.filterChecked.rarityCharacters">
                                <span class="checkmark"></span>
                            </label>
                            <label class="containers">Axe
                                <input type="checkbox" value="axe" v-model="this.app.filterChecked.rarityCharacters">
                                <span class="checkmark"></span>
                            </label>
                            <label class="containers">Sickle
                                <input type="checkbox" value="sickle" v-model="this.app.filterChecked.rarityCharacters">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="text-xl mt-5">
                        Rarity
                        <div class="mt-5">
                            <label class="containers">Common
                                <input type="checkbox" value="common" v-model="this.app.filterChecked.rarity">
                                <span class="checkmark"></span>
                            </label>
                            <label class="containers">Uncommon
                                <input type="checkbox" value="uncommon" v-model="this.app.filterChecked.rarity">
                                <span class="checkmark"></span>
                            </label>
                            <label class="containers" style="color:#8961FC;">Rare
                                <input type="checkbox" value="rare" v-model="this.app.filterChecked.rarity">
                                <span class="checkmark"></span>
                            </label>
                            <label class="containers" style="color:#D839D2;">Epic
                                <input type="checkbox" value="epic" v-model="this.app.filterChecked.rarity">
                                <span class="checkmark"></span>
                            </label>
                            <label class="containers" style="color:#FAFF02;">Legendary
                                <input type="checkbox" value="legendary" v-model="this.app.filterChecked.rarity">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .card-filter {
        background: #42210B;
        @apply rounded-xl;
        border: 3px solid #FFBE2A;
    }

    /* Customize the label (the container) */
    .containers {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .containers input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

    }

    .checkmark {
        border-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #3A3838;
        border: 2px solid #DBFF00;
    }

    /* On mouse-over, add a grey background color */
    .containers:hover input~.checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .containers input:checked~.checkmark {
        background-color: #DBFF00;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .containers input:checked~.checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .containers .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
</style>
<script>
    export default {
        data() {
            return {
                app: this.$parent
            }
        }
    }
</script>
