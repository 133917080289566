<template>
  <div class=" py-5">
    <div class="card mx-auto bg-greyTransparent" style="width: 90%;border-radius: 20px;">
      <div class="card-body mx-md-5 mt-3">
<!--        <div class="card mx-auto mt-5 bg-blackCustom2">-->
<!--          <div class="card-body mx-md-5">-->
<!--            <div class="row">-->
<!--              <div class="col-md-3 d-inline-flex mb-1">-->
<!--                <img src="/images/fnf.svg" height="64" width="64">-->
<!--                <div class="ms-3 mt-1">-->
<!--                  My FNF-->
<!--                  <h2>{{ user.formatedFNF }}</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <h1 class="text-blackCustom md:text-left text-center mb-5" style="font-weight: bolder">PROFILE</h1>
     <BalanceDetail :user="user"/>
        <div class="row mt-5">
          <div class="col-md-12" style="  overflow: auto;
  white-space: nowrap;">
            <button class="btn btn-fighter px-4 me-3 bg-gray-1 shadow-lg font-bold text-white" @click="filter = 'characters'"
              :class="{ 'btn-active':filter == 'characters' }"><img class="me-3 h-12" src="/images/icon_figther.svg" >Characters</button>
            <button class="btn btn-fighter px-4 me-3 bg-gray-1 shadow font-bold  text-white" @click="filter = 'gameItem'"
              :class="{ 'btn-active':filter == 'gameItem' }"><img class="me-3 h-12" src="/images/items.svg"> Items</button>
            <!-- <button class="btn btn-cust btn-non px-4  me-4" @click="this.filter='tools'"
              :class="{ 'btn-active':filter == 'tools' }"><b>Tools</b></button> -->
            <!-- <button class="btn btn-cust btn-non px-4 me-4" @click="this.filter='lands'"
              :class="{ 'btn-active':filter == 'lands' }"><b>Lands</b></button> -->

          </div>
          <div class="col-md-12 mt-5">
            <div v-if="filter == 'characters'">
              <div v-if="!character">
               <h2 class="text-blackCustom text-center py-10">No characters to display</h2>
              </div>
              <div class="grid md:grid-cols-4 lg:grid-cols-5 gap-8">
              <div v-for="character in characters" :key="character.id">
                  <div class="rounded-lg p-6 flex items-center h-72" style="background: rgba(153, 153, 153, 0.5);">
                    <img :src="`https://nft.farmandfight.io/${character.nft_id}.png`" alt="" class="mx-auto h-32 w-32 -mt-24">
                  </div>
                  <div class="rounded-lg bg-blackCustom2 py-4 -mt-24 px-6">

                    <div class="grid grid-cols-2">

                      <p class="text-white font-bold">#{{character.nft_id}} <br/>
                      </p>
                      <span class="text-yellow-1 font-bold text-right">LVL {{ getTraitValue(character.character.attributes, 'Level') }}</span>

                    </div>
                    <button class="bg-pinkCustom border-0 rounded-lg text-white px-2" style="font-weight: bold" @click="sellCharacterModal(character)">SELL</button>


                  </div>
                </div>
                </div>

            </div>
            <div v-if="filter == 'gameItem'">
              <div v-if="!balance">
                <h2 class="text-blackCustom text-center py-10">No items to display</h2>
              </div>
              <div class="grid md:grid-cols-4 lg:grid-cols-5 gap-8">
                <div v-for="balance in balances" :key="balance.id">
                  <div class="rounded-lg p-6 flex items-center h-72" style="background: rgba(153, 153, 153, 0.5);">
                    <img :src="balance.image" alt="" class="mx-auto h-24 w-32 -mt-24">
                  </div>
                  <div class="rounded-lg bg-blackCustom2 py-4 -mt-24 px-6">
                    <div class="grid grid-cols-2">
                      <div class="rounded-lg bg-gray-500 pt-4 px-4 grid grid-cols-2 items-center col-span-2 mb-4 text-sm" v-if="balance.pending > 0">
                         <p class="font-bold"> {{ balance.pending }} {{ balance.name }}</p>
                         <p class="text-yellow-1 text-right">Claim</p>
                      </div>
                      <p class="text-white font-bold">#{{balance.nft_id}}</p>
                      <p class="text-yellow-1 font-bold text-right">{{ balance.name }} <br/>
                      {{ balance.balance }}</p>
                      <button class="bg-pinkCustom border-0 rounded-lg text-white"  style="font-weight: bold" @click="sellItemModal(balance)">SELL</button>
                    </div>

                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    <div v-if="itemSell != null">
          <ModalItemSell :item="itemSell" />

    </div>
    <div v-if="characterSell != null">
          <ModalCharacterDetail :nftId="characterSell" />

    </div>
  </div>
</template>
<script>
  import {
    useUserStore
} from '@/stores/user'
  import ModalItemSell from '@/components/Marketplace/Item/ModalSell.vue'
  import ModalCharacterDetail from '@/components/Marketplace/Character/ModalSell.vue'
  import BalanceDetail from "@/components/BalanceDetail";


  export default {
    name: 'Dashboard',
    components: {
      BalanceDetail,
      ModalItemSell,
      ModalCharacterDetail
    },
    setup: () => {

      const user = useUserStore()
      return {
        user: user,
      }
  },
  data: () => ({
      filter: 'characters',
      items: [],
      balances: [],
      characters: [],
      characterSell: null,
      itemSell: null,

  }),
  methods: {
    getTraitValue: function (traits, name) {
      return traits.filter((trait) => trait.trait_type == name)[0].value
    },
    sellItemModal: async function (balance) {
      this.itemSell = null
      await this.$nextTick()
      this.itemSell = balance
    },
    sellCharacterModal: async function (character) {
      this.characterSell = null
      await this.$nextTick()
      this.characterSell = character.nft_id
    },
    bridge: async function (itemId) {
      let response = await this.user.api.post('bridge', {
          itemId: itemId
      })

      this.user.bridge.methods.bridge(response.data.message, response.data.signature).send({from: this.user.address})
    },
    getItems: async function() {
      let response = await this.user.api.get('nft/item')
      this.items = response.data
      this.user.registerCallback(this.getBalance.bind(this))
    },
    getCharacter: async function () {
      this.characters = (await this.user.api.get(`/user/${this.user.address}/characters`)).data
    },
    getBalance: async function () {
      let that = this
      this.items.forEach(async function (item) {
        let [pending, onchain] = await Promise.all([
          that.user.api.get('game/item/' + item.nft_id),
          that.user.gameItem.methods.balanceOf(that.user.address, item.nft_id).call()
        ])

        item.balance = onchain
        item.pending = pending.data.quantity
        if(item.balance > 0 || item.pending > 0) that.balances.push(item)

      })
    }

    },
    mounted() {
      document.title = "My Profile | FarmAndFight"
      this.getItems()
      this.getCharacter()

    }
  }
</script>
<style scoped>
  .btn-cust {
    border-radius: 10px !important;
    font-size: 1.5em;
  }

  .btn-active {
    background: #50E91A !important;
    color: #353333 !important;
  }

  .btn-non {
    background: #5E5E5E;
    color: white;
  }
</style>
