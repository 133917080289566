<template>
    <div class="modal fade" id="modalSell" tabindex="-1" aria-labelledby="modal" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content card-modal">
                <div class="modal-header border-0">
                    <button type="button" class="rounded-full border-0 w-10 h-10 flex items-center justify-center bg-brownCustom" data-bs-dismiss="modal"
                        aria-label="Close"><i class="fa-solid fa-xmark text-white "></i></button>
                </div>
                <div class="modal-body md:px-10 py-20 -mt-5">
                    <div class="grid grid-cols-12">
                        <div class="md:col-span-7 col-span-12 grid">
                            <img :src="item.image" class="mx-auto mt-32">
                        </div>
                        <div class="card md:mt-0 mt-10 rounded-2xl shadows bg-greyTransparent2 md:col-span-5 col-span-12">
                            <div class="card-body">
                                <div class="flex">
                                    <div class="font-bold">
                                        <span class="text-3xl">Sell {{ item.name }}</span><br>
                                    </div>
                                </div>
                                <label class="w-100 mt-6">Amount <span class="float-right">Own : {{ item.balance }}</span></label>
                                <input @keypress="this.$root.onlyNumber" class="bg-greyCS2 border-2 border-greyTransparent appearance-none rounded w-full py-2 px-4 text-white leading-tight" v-model="amount" />
                                <label class="mt-4">Price per Item</label>
                                <input @keypress="this.$root.onlyNumber" class="bg-greyCS2 border-2 border-greyTransparent appearance-none rounded w-full py-2 px-4 text-white leading-tight" v-model="price_per_item" />
                                <div class="mt-5">Description Items</div>
                                <div class="grid md:grid-cols-1 gap-2 mt-2">
                                    <p class="bg-greyCS2 p-2 rounded-xl text-center py-4">{{ item.description }}</p>
                                </div>
                                <div class="md:grid grid-cols-2 mt-5">
                                    <div class="md:mx-0 mx-auto flex items-center">

                                    </div>
                                    <div class="">
                                        <button
                                            class="md:ml-4 shadow bg-greenCustom py-2 px-10 rounded-lg border-0 text-white font-bold float-right" v-if="!approved" @click="approve">Approve</button>
                                        <button
                                            class="md:ml-4 shadow bg-greenCustom py-2 px-10 rounded-lg border-0 text-white font-bold float-right" v-if="approved" @click="sell">SELL</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<style>
.card-modal {
    @apply rounded-xl bg-blackCustom;
    border: 3px solid #FFBE2A;
}

/* Customize the label (the container) */
.containers {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.containers input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

}

/*.checkmark {*/
/*    border-radius: 10px;*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    height: 25px;*/
/*    width: 25px;*/
/*    background-color: #3A3838;*/
/*    border: 2px solid #DBFF00;*/
/*}*/

/* On mouse-over, add a grey background color */
.containers:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containers input:checked~.checkmark {
    background-color: #DBFF00;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.containers input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.containers .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
</style>
<script>
import {
    useUserStore
} from '@/stores/user'
export default {
    props: {
        item: Object,
    },
    setup() {

        const user = useUserStore()
        return {
            user: user,
        }
    },
    data() {
        return {
            price_per_item: '0',
            amount: 1,
            approved: false,
        }
    },
    methods: {
        async sell() {
            this.user.marketplace_item.methods.sell(this.item.nft_id, this.amount, this.user.web3.utils.toWei(this.price_per_item)).send({from: this.user.address})
            .on('receipt', function (log) {
                console.log(log)
            })
        },
        async approve() {
            let that = this
            this.user.gameItem.methods.setApprovalForAll(this.user.marketplace_item._address, true).send({from: this.user.address})
            .on('receipt', function () {
                that.checkApprove()
            })

        },
        async checkApprove() {
            this.approved = await this.user.gameItem.methods.isApprovedForAll(this.user.address, this.user.marketplace_item._address).call();
        }
    },
    mounted() {
        this.checkApprove()
        var myModal = new window.bootstrap.Modal(document.getElementById('modalSell'))
        myModal.show()

    }
}
</script>
