<template>
  <div class="marketplace md:pt-10">
    <div v-if="!this.$parent.$parent.loading"
      class="card md:w-11/12 w-full md:rounded-2xl md:bg-greyTransparent bg-transparent border-0 rounded-none md:mt-0 mx-auto">
      <div class="card-body mx-md-5 md:mt-3 my-md-5">
        <div class="md:static sticky top-0 pt-2 pb-5 " style="z-index:1">
          <div class="grid md:grid-cols-2">
            <button
              class="absolute float-left mt-1 bg-transparent md:hidden border-0 text-white hover:opacity-60 focus:opacity-60"
              data-bs-toggle="modal" data-bs-target="#modalFilter"><i class="fa-solid text-white fa-filter"></i>
              Filter</button>
            <h1 class="text-blackCustom md:text-left text-center mb-5" style="font-weight: bolder">MARKETPLACE</h1>
          </div>

         <BalanceDetail :user="user" />

          <div class="grid grid-cols-2">

          <div style="overflow: auto;white-space: nowrap;">
            <button class="btn btn-fighter px-4 me-3" :class="{'btn-fighter-active':filterMarket =='fighter'}"
              @click="filterMarket='fighter'"><img class="me-3 h-12" src="/images/icon_figther.svg">Fighter</button>
            <button class="btn btn-fighter px-4 me-3" :class="{'btn-fighter-active':filterMarket =='items'}"
              @click="filterMarket='items'" ><img class="me-3 h-12" src="/images/items.svg">Items</button>
<!--            style="padding-top:14px;padding-bottom:14px;"-->
            <!-- <button class="btn btn-fighter px-4 me-3" :class="{'btn-fighter-active':filterMarket =='land'}"
            @click="filterMarket='land'"><img class="me-3" src="/images/icon_figther.svg">Land</button>
          <button class="btn btn-fighter px-4" :class="{'btn-fighter-active':filterMarket =='landItems'}"
            @click="filterMarket='landItems'"><img class="me-3" src="/images/icon_figther.svg">Land items</button> -->
          </div>
          <div class="md:ml-auto md:mb-0 mb-5">
            <select class="text-xl btn-recently mt-md-0" v-model="sort">
              <option value="created_at,desc">Recently Listed</option>
              <option value="price,asc">Lowest Price</option>
              <option value="price,desc">Highest Price</option>
            </select>
          </div>
        </div>
        </div>

        <div class="row mt-5">
          <div class="col-xxl-2 col-sm-3">
            <div class="card card-filter md:block hidden p-5">
              <div class="text-xl">
                Farming Items
                <div class="grid grid-cols-2 mt-5">
                  <label class="containers">Ore
                    <input type="checkbox" value="1" @change="getListingItems" v-model="filterChecked.farmingItems">
                    <span class="checkmark"></span>
                  </label>
                  <label class="containers">Wood
                    <input type="checkbox" value="2" @change="getListingItems" v-model="filterChecked.farmingItems">
                    <span class="checkmark"></span>
                  </label>
                  <label class="containers">Food
                    <input type="checkbox" value="3" @change="getListingItems" v-model="filterChecked.farmingItems">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div class="text-xl mt-5">
                Rarity Characters
                <div class="mt-5">
                  <label class="containers">PickAxe
                    <input type="checkbox" value="pickaxe" v-model="filterChecked.rarityCharacters">
                    <span class="checkmark"></span>
                  </label>
                  <label class="containers">Axe
                    <input type="checkbox" value="axe" v-model="filterChecked.rarityCharacters">
                    <span class="checkmark"></span>
                  </label>
                  <label class="containers">Sickle
                    <input type="checkbox" value="sickle" v-model="filterChecked.rarityCharacters">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div class="text-xl mt-5">
                Rarity
                <div class="mt-5">
                  <label class="containers">Common
                    <input type="checkbox" value="common" v-model="filterChecked.rarity">
                    <span class="checkmark"></span>
                  </label>
                  <label class="containers">Uncommon
                    <input type="checkbox" value="uncommon" v-model="filterChecked.rarity">
                    <span class="checkmark"></span>
                  </label>
                  <label class="containers" style="color:#8961FC;">Rare
                    <input type="checkbox" value="rare" v-model="filterChecked.rarity">
                    <span class="checkmark"></span>
                  </label>
                  <label class="containers" style="color:#D839D2;">Epic
                    <input type="checkbox" value="epic" v-model="filterChecked.rarity">
                    <span class="checkmark"></span>
                  </label>
                  <label class="containers" style="color:#FAFF02;">Legendary
                    <input type="checkbox" value="legendary" v-model="filterChecked.rarity">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <!-- <p class="text-center" style="font-size:3.5em;margin-top:20vh">Coming Soon</p> -->

            </div>
          </div>
          <div class="col md:mt-0">
            <div v-if="filterMarket=='fighter'" class="grid 2xl:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-3">
              <div v-for="listing in listingCharacters" :key="listing.listing_id" class="card card-item cursor-pointer hover:opacity-75"
                style="z-index:0" @click="modalListingCharDetail(listing)">
                                <img :src="`https://nft.farmandfight.io/${listing.character_id}.png`"  class="mx-auto md:pb-48 pb-36 p-4 md:h-auto h-96 w-52">

                <div class="card card-item2 py-2 w-full md:px-4 px-2 bottom-0 absolute">

                  <div class="md:text-base text-xs">#{{ listing.character_id }}</div>
                  <div class="md:text-base text-xs" style="color:#60EE2F;"></div>
                  <div class="md:text-base text-xs">
                    <div class="grid grid-cols-2 md:pt-5 pt-2">
                      Common
                      <div class="text-right"><b class="md:text-xl text-sm">Price</b>
                        <div class="truncate">{{ user.web3.utils.fromWei(listing.price.toString())  }}</div> FNF
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="filterMarket=='items'" class="grid 2xl:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-3">
              <div v-for="listing in listingItems" :key="listing.id" class="card card-filter p-4 cursor-pointer hover:opacity-75" @click="modalListingItemDetail(listing)" style="z-index:0">
                <div class="bg-blackCustom rounded-xl p-3 h-72 flex items-center">
                    <img :src="listing.item.image" class="h-24 w-32 mx-auto">
                </div>

                <div class="md:text-base text-xs">
                  <div class=" md:pt-5 pt-2">
                    <div class="grid grid-cols-2 w-100">
                      <p class="font-bold md:text-base text-xs">{{ listing.item.name }} <br/> Qty {{ listing.remaining_amount }} </p>
                      <p class="font-bold md:text-lg text-sm yext-yellow-1 text-right text-yellow-1">{{ user.web3.utils.fromWei(listing.price.toString()) }}<span
                          class="font-bold md:text-lg text-sm ml-2">FNF</span> / each</p>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <ModalFilter />
    <div v-if="listingItemDetail != null">
      <ModalItemDetail :listing_id="listingItemDetail" />
    </div>
    <div v-if="listingCharacterDetail != null">
      <ModalCharDetail :listing_id="listingCharacterDetail" />
    </div>
</template>

<style>
</style>

<script>
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'
  import ModalFilter from '@/components/Marketplace/ModalFilter.vue'
  import ModalItemDetail from '@/components/Marketplace/Item/ModalDetail.vue'
  import ModalCharDetail from '@/components/Marketplace/Character/ModalDetail.vue'
  import {
    useUserStore
} from '@/stores/user'
  import BalanceDetail from "@/components/BalanceDetail.vue";
  export default {
    name: 'Home',
    components: {
      BalanceDetail,
      ModalFilter,
      ModalItemDetail,
      ModalCharDetail,
    },
    setup: () => {

        const user = useUserStore()
        return {
          user: user,
        }
    },
    data: () => ({
      modalDetail: null,
      filterMarket: 'fighter',
      filterChecked: {
        farmingItems: [],
        rarityCharacters: [],
        rarity: [],
      },
      sort: "created_at,desc",
      listingItems: [],
      listingItemDetail: null,
      listingCharacters: [],
      listingCharacterDetail: null,
      loading: true,
      load: false,
      modal: false,
      nftId: 0
    }),
    watch: {
      sort: function () {
        this.getListingItems()
        this.getListingCharacters()
      }
    },
    methods: {
      async getListingItems() {
        this.listingItems = (await this.user.api.get(`/marketplace/item/search?page=0&itemId=${this.filterChecked.farmingItems}&sortBy=${this.sort}`)).data
      },
      async getListingCharacters() {
        this.listingCharacters = (await this.user.api.get(`/marketplace/character/search?page=0&itemId=${this.filterChecked.rarityCharacters}&sortBy=${this.sort}`)).data
      },
      async modalListingItemDetail(listing) {
        this.$router.push({
          name:"MarketplaceItemDetail",
          params: {
            id: listing.listing_id
          }
        })
        this.listingItemDetail = null;
        await this.$nextTick();
        this.listingItemDetail = listing.listing_id
      },
      async modalListingCharDetail(listing) {
        this.$router.push({
          name:"MarketplaceCharacterDetail",
          params: {
            id: listing.listing_id
          }
        })
        this.listingCharacterDetail = null;
        await this.$nextTick();
        this.listingCharacterDetail= listing.listing_id
        // this.modalDetail.show()
        // this.$forceUpdate()
        // this.nftId = nftId
      },
      unityLoaded() {
        let that = this
        setTimeout(() => {
          that.$parent.$parent.loading = false
          if(that.$route.name == "MarketplaceDetail"){
          that.modalDetail.show()
          that.nftId = that.$route.params.id
      }
        }, 5000)
      }
    },
    mounted() {
     document.title = "Marketplace | FarmAndFight"

      let that = this
      this.getListingItems()
      this.getListingCharacters()
      this.$nextTick(() => {
        that.load = true
      })
    }

  }
</script>
<style scoped>
  .btn {
    font-weight: bold;
    border-radius: 30px;
    font-size: 2.8vh;
  }

  .btn-market {
    background: #50E91A;
    color: #353333;
  }

  .card-filter {
    @apply rounded-xl bg-greyCS1 border-2 border-greyTransparent2;
    font-weight:bold;
  }

  .card-item {
    @apply rounded-xl bg-greyTransparent2;
  }

  .card-item2 {
    background: #1C1C1C;
    @apply rounded-xl;
    /*outline: 3px solid #FF3333;*/
  }

  .btn-fighter {
    background: #5E5E5E;
    color: white;
    border-radius: 12px !important;
  }

  .btn-fighter-active {
    background: #50E91A !important;
    color: #353333 !important;
  }

  .btn-mystery {
    background: #515151;
    color: white
  }

  .btn-recently {
    @apply bg-blackCustom text-white rounded-lg p-3;
  }
</style>
