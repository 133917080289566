<template>
  <div>
  <div class="card rounded-xl mx-auto my-5 bg-blackCustom2">
    <div class="card-body mx-md-5 my-5">
      <div class="row">
        <div class="col-md-3 d-inline-flex mb-1 ">
          <img src="/images/fnf.svg" height="64" width="64">
          <div class="ms-3 mt-1">
            <span class="font-bold">MY FNF</span>
            <h2>{{ user.formatedFNF }}</h2>
          </div>
        </div>

        <div class="col-md-3 d-inline-flex mb-1 ">
          <img src="/images/profileStone.svg" height="64" width="64">
          <div class="ms-3 mt-1">
             <span class="font-bold">MY STONE</span>
            <h2>{{ user.formatedStone }}</h2>
          </div>
        </div>

        <div class="col-md-3 d-inline-flex mb-1 ">
          <img src="/images/profileWood.svg" height="64" width="64">
          <div class="ms-3 mt-1">
             <span class="font-bold">MY WOOD</span>
            <h2>{{ user.formatedWood }}</h2>
          </div>
        </div>

        <div class="col-md-3 d-inline-flex mb-1 ">
          <img src="/images/profileFood.svg" height="64" width="64">
          <div class="ms-3 mt-1">
             <span class="font-bold">MY FOOD</span>
            <h2>{{ user.formatedFood }}</h2>
          </div>
        </div>

      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  props: ['user'],
mounted(){

}
}
</script>
