<template>
  <div class="pt-10">
    <div class="card mx-auto bg-greyTransparent w-11/12" style="border-radius: 20px;">
      <div class="card-body md:mx-10 mt-3 my-3">
        <div class="card rounded-xl mx-auto my-5 bg-blackCustom2">
          <div class="card-body mx-md-5 my-5">
            <h1 class="text-4xl font-bold" style="font-family: luckyFont">CHANGE ITEMS TO BLOCKCHAIN</h1>
            <p class="text-lg font-bold">If you want sell items you must be change items to blockchain items,<br>
              and same if you want use the items you must be trade blockchain items to items server<br>
              trade will get gas fee.</p>
          </div>
        </div>
        <div class="card rounded-xl mx-auto my-5 bg-brownCustom">
          <div class="card-body mx-md-5 my-5">
            <div class="grid md:grid-cols-3">
              <!-- Game -->
              <div class="">
                <h1 class="strokeme" style="font-family: luckyFont">INGAME ITEMS</h1>
                <div >
                    <div class="grid grid-cols-4 gap-4 ">
                      <div v-for="(box,index) in 16"
                           draggable
                           @dragstart="startDrag($event,  listOne[index])"
                           @drop="onDrop($event, 1)"
                           @drop.stop.prevent
                           @click.stop.prevent
                           @dragover.prevent
                           @dragenter.prevent
                           :key="box.id" class="card grid place-items-center bg-brownCustom4 2xl:w-28 2xl:h-28 w-20 h-20">
                      <div v-if="index < listOne.length" class="itemsGrab">
                          <img onContextMenu="return false;"  class="w-full h-full relative" :src="listOne[index].images"/>
                        <p style="font-family: luckyFont" class="absolute 2xl:text-2xl text-xl bottom-0 -mb-0.5 right-1">{{ listOne[index].quantity }}</p>
                      </div>
                      </div>
                    </div>
                </div>

              </div>

              <!-- Middle -->
              <div class="grid grid-cols-1 place-self-center">
                <p class="mx-auto">
                  <i class="fa-solid fa-arrow-left-long fa-10x strokeme"></i><br>
                  <i class="fa-solid fa-arrow-right-long fa-10x strokeme leading-8"></i>
                </p>
                <p class="strokeme text-xl text-center" style="font-family: luckyFont">Drag and Drop</p>
                <img @click="swap" class="mx-auto w-3/5 cursor-pointer" src="images/btnSwap.svg"/>
              </div>

              <!-- Blockchain -->
              <div class="">
                <h1 class="strokeme" style="font-family: luckyFont">BLOCKCHAIN</h1>
                <div>
                  <div class="grid grid-cols-4 gap-4 ">
                    <div v-for="(box,index) in 16"
                         draggable
                         @dragstart="startDrag($event, listTwo[index])" @drop="onDrop($event, 2)"
                         @drop.stop.prevent
                         @dragover.prevent
                         @dragenter.prevent
                         :key="box.id" class="card grid place-items-center bg-brownCustom4 2xl:w-28 2xl:h-28 w-20 h-20">
                      <div v-if="index < listTwo.length" class="itemsGrab">
                        <img class="w-full h-full relative" :src="listTwo[index].images"/>
                        <p style="font-family: luckyFont" class="absolute 2xl:text-2xl text-xl bottom-0 -mb-0.5 right-1">{{ listTwo[index].quantity }}</p>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




  </div>
  </div>
</template>
<style>
.strokeme {
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
</style>
<script>

export default {

  data (){
    return{
      items: [
        {
          id: 0,
          images:"images/foods.svg",
          quantity: 200,
          list:1
        },
        {
          id: 1,
          images: 'images/ores.svg',
          quantity: 200,
          list:1
        },
        {
          id: 3,
          images: 'images/ores.svg',
          quantity: 100,
          list:2
        },
      ],
      itemsChain: [
        {
          id: 0,
          images: 'images/ores.svg',
          quantity: 100,
        },
      ]
    }
  },
  mounted() {

    },
  computed: {
  listOne() {
    return this.items.filter((item) => item.list === 1)
},
  listTwo() {
    return this.items.filter((item) => item.list === 2)
},
},



methods: {
    swap(){
      console.log("Test")
    },
  startDrag(evt, item) {
  evt.dataTransfer.dropEffect = 'move'
  evt.dataTransfer.effectAllowed = 'move'
  evt.dataTransfer.setData('itemID', item.id)
},
  onDrop(evt, list) {
      evt.preventDefault();
    const itemID = evt.dataTransfer.getData('itemID')
      const item = this.items.find((item) => item.id == itemID)
      item.list = list
},
  }
}
</script>

<style>
.itemsGrab{
  cursor: grab;
}

</style>
