<template>
  <div class="modal fade" id="modalCharDetail" tabindex="-1" aria-labelledby="modal" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content card-modal-detail-char"  v-if="!loading">
        <div class="modal-header border-0">
          <button type="button" class="rounded-full border-0 w-10 h-10 flex items-center justify-center bg-brownCustom"
                  @click="this.$router.push('/marketplace')" data-bs-dismiss="modal" aria-label="Close"><i
              class="fa-solid fa-xmark text-white "></i></button>
        </div>
        <div class="modal-body md:px-10 py-20 -mt-5">
          <div class="grid grid-cols-12">
            <div class="md:col-span-7 col-span-12 grid">
              <div id="rendernft"></div>
            </div>
            <div class="card md:mt-0 mt-10 rounded-2xl shadows bg-greyTransparent2 md:col-span-5 col-span-12">
              <div class="card-body">
                <div class="flex">
                  <div class="font-bold">
                    <span class="text-3xl">Character {{ listing.characterId }} </span><br>
                    #{{ listing.characterId }}
                  </div>
                  <div class="ml-auto">
                    <img class="md:h-14 h-10" src="/images/EPIC.svg">
                  </div>
                </div>
                <div class="flex md:items-center mt-5 mb-3">
                  <div>
                    <label class="block font-bold md:text-right mb-1 md:mb-0 pr-4"
                           for="inline-full-name">
                      <img class="md:w-11 w-10" src="/images/owner.svg">
                    </label>
                  </div>
                  <div class="w-full">
                    <input
                        class="bg-greyCS2 appearance-none border-0 rounded w-full py-2 px-4 text-white leading-tight"
                        id="inline-full-name" disabled type="text"
                        :value="listing.seller">
                  </div>
                </div>
                <div class="flex md:items-center mb-3">
                  <div>
                    <label class="block font-bold md:text-right mb-1 md:mb-0 pr-4"
                           for="inline-full-name">
                      <img class="md:w-11 w-10" src="/images/type.svg">
                    </label>
                  </div>
                  <div class="w-full">
                    <input
                        class="bg-greyCS2 appearance-none border-0 rounded w-full py-2 px-4 text-white leading-tight"
                        id="inline-full-name" disabled type="text" value="First Character 5000">
                  </div>
                </div>
                <div class="flex md:items-center">
                  <div>
                    <label class="block font-bold md:text-right mb-1 md:mb-0 pr-4"
                           for="inline-full-name">
                      <img class="md:w-11 w-10" src="/images/effect.svg">
                    </label>
                  </div>
                  <div class="w-full">
                    <input
                        class="bg-greyCS2 appearance-none border-0 rounded w-full py-2 px-4 text-greenCustom2 leading-tight"
                        id="inline-full-name" disabled type="text"
                        value="timing reduction mining by 15%">
                  </div>
                </div>
                <!-- <div class="flex mt-5">
                    <div class="list-none">
                         <img class="md:h-14 h-10" src="/images/owner.svg">
                        <li>Owner</li>
                    </div>
                    <div class="list-none w-full ml-5 bg-greyCS2">
                        <li>as123523dvrew23t2g34g34523cf2</li>
                        <li>as123523dvrew23t2g34g34523cf2</li>
                    </div>
                </div> -->
                <div class="mt-5 font-bold">Character Description</div>
                <div class="grid md:grid-cols-2 gap-2 mt-2">
                  <p class="bg-greyCS2 p-2 rounded-xl text-center text-greenCustom2">Health : 100</p>
                  <p class="bg-greyCS2 p-2 rounded-xl text-center text-greenCustom2">Energy : 100</p>
                </div>
                <div>
                </div>
                <div class="md:flex md:float-right mx-auto py-5 grid gap-y-5" v-if="listing">
                  <div class="md:mx-0 mx-auto">
                    <span class="font-bold text-2xl">{{ user.web3.utils.fromWei(listing.price) }} FNF</span>
                    <div class="text-xs text-right">/ 60.6 $</div>
                  </div>
                  <div v-if="owner == user.address">
                    <p class="text-2xl font-bold border-top border-2 pt-2">SELL</p>
                    <label class="font-bold">PRICE</label>
                    <input  @keypress="this.$root.onlyNumber"
                            class="bg-greyCS2 appearance-none rounded-lg w-full py-2 px-4 text-white leading-tight"
                            v-model="price" />
                    <div class="md:flex md:float-right mx-auto py-5 grid gap-y-5">
                      <div class="grid mt-4">
                        <button
                            class="md:ml-4 shadow bg-pinkCustom py-2 px-10 rounded-lg border-0 text-white font-bold" @click="sell" v-if="approved">SELL</button>
                        <button
                            class="md:ml-4 shadow bg-greenCustom py-2 px-10 rounded-lg border-0 text-white font-bold" @click="approve" v-if="!approved">APPROVE</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<style>
.card-modal-detail-char {
  @apply rounded-xl bg-blackCustom;
  border: 3px solid #FFBE2A;
}

/* Customize the label (the container) */
.containers {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containers input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

}

/*.checkmark {*/
/*    border-radius: 10px;*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    height: 25px;*/
/*    width: 25px;*/
/*    background-color: #3A3838;*/
/*    border: 2px solid #DBFF00;*/
/*}*/

/* On mouse-over, add a grey background color */
.containers:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containers input:checked~.checkmark {
  background-color: #DBFF00;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containers input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containers .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
<script>
import {
  useUserStore
} from '@/stores/user'

export default {
  props: {
    listing_id: Number
  },
  setup: () => {
    const user = useUserStore()
    return {
      user: user,
    }
  },
  data() {
    return {
      app: this.$parent,
      approved: false,
      character: null,
      owner: '',
      loading: true,
      listing: null,
      price: '0'
    }
  },
  methods: {
    async checkOwner() {
      this.owner = (await this.user.character.methods.ownerOf(this.nftId).call()).toLowerCase()
    },
    async approve() {
      let that = this
      let approve_amount = '115792089237316195423570985008687907853269984665640564039457584007913129639935'; //(2^256 - 1 )
      this.user.fnf.methods.approve(this.user.marketplace_character._address, approve_amount).send({from: this.user.address})
          .on('receipt', function () {
            that.checkApproved()
          })
    },
    async getItemInfo() {
      this.character = (await this.user.api.get('/nft/character/' + this.listing.characterId)).data
      this.loading = false;
    },
    async getListingInfo() {
      this.listing = await this.user.marketplace_character.methods.listing(this.listing_id).call()
      console.log(this.listing)
      this.getItemInfo()
    },

    async sell() {
      this.user.marketplace_character.methods
          .sell(this.nftId, this.user.web3.utils.toWei(this.price))
          .send({ from: this.user.address })
          .on('receipt', function (log) {
            console.log(log)
          })
    },
    async checkApprove() {
      this.approved =  parseInt(await this.user.fnf.methods.allowance(this.user.address, this.user.marketplace_character._address).call()) > 0
    },
  },
  mounted() {
    var myModal = new window.bootstrap.Modal(document.getElementById('modalCharDetail'))
    myModal.show()
    this.getListingInfo()
    // this.checkOwner()
    this.checkApprove()
    console.log(this.approved)
  }
}
</script>
