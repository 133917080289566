import { defineStore } from 'pinia'
import axios from 'axios'

import Web3 from 'web3'
import Bridge from '../abi/Bridge';
import FarmAndFight from '../abi/FarmAndFight';
import GameItem from '../abi/GameItem';
import ABI_MARKETPLACE_ITEM from '../abi/MarketplaceItem';
import ABI_MARKETPLACE_CHARACTER from '../abi/MarketplaceCharacter';
import ABI_CHARACTER from '../abi/Character';
import config from '../config';
let web3 = new Web3(config.RPC_URL)
export const useUserStore = defineStore('user', {
  state: () => {

        return {
            address: null,
            web3: web3,
            signature: null,
            fnf: null,
            gameItem: new web3.eth.Contract(GameItem, config.GAMEITEM),
            marketplace_item: new web3.eth.Contract(ABI_MARKETPLACE_ITEM, config.MARKETPLACE_ITEM),
            marketplace_character: new web3.eth.Contract(ABI_MARKETPLACE_CHARACTER, config.MARKETPLACE_CHARACTER),
            character: new web3.eth.Contract(ABI_CHARACTER, config.CHARACTER),
            fnfBalance: '0',
            stoneBalance: '0',
            woodBalance: '0',
            foodBalance: '0',
            bridge: null,
            api: axios.create({ baseURL: config.API_URL }),
            callbacks: [],
        }
    },
    getters: {
        formatedFNF: (state) => parseInt(state.web3.utils.fromWei(state.fnfBalance)).toLocaleString(),
        formatedStone: (state) => parseInt(state.stoneBalance).toLocaleString(),
        formatedWood: (state) => parseInt(state.woodBalance).toLocaleString(),
        formatedFood: (state) => parseInt(state.foodBalance).toLocaleString(),
    },
  // could also be defined as
  // state: () => ({ count: 0 })
    actions: {
        async reconnect() {

        },
        registerCallback(callback) {
            if(this.address != null) callback()
            this.callbacks.push(callback);
        },
      async connect() {
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

          let self = this
          window.ethereum.on('accountsChanged', function (accounts) {
              self.web3 = accounts[0]
              self.web3 = new Web3(window.ethereum);
          });

          this.web3 = new Web3(window.ethereum);
          var message = `FarmAndFight.${Date.now()}`
          this.signature = await this.web3.eth.personal.sign(message, accounts[0])
          this.api = axios.create({
              baseURL: config.API_URL,
              headers: { 'Authorization': 'Bearer ' + Buffer.from(`${this.signature}:${message}:${accounts[0]}`).toString('base64') }
          })
          this.address = accounts[0]
          this.fnf = new this.web3.eth.Contract(FarmAndFight, config.FARMANDFIGHT)
          this.fnfBalance = await this.fnf.methods.balanceOf(this.address).call()
          this.gameItem = new this.web3.eth.Contract(GameItem, config.GAMEITEM)
          this.stoneBalance = await this.gameItem.methods.balanceOf(this.address, 1).call()
          this.woodBalance = await this.gameItem.methods.balanceOf(this.address, 2).call()
          this.foodBalance = await this.gameItem.methods.balanceOf(this.address, 3).call()
          this.bridge = new this.web3.eth.Contract(Bridge, config.BRIDGE);
          this.marketplace_item = new this.web3.eth.Contract(ABI_MARKETPLACE_ITEM, config.MARKETPLACE_ITEM)
          this.marketplace_character = new this.web3.eth.Contract(ABI_MARKETPLACE_CHARACTER, config.MARKETPLACE_CHARACTER)
          this.character = new this.web3.eth.Contract(ABI_CHARACTER, config.CHARACTER)


          this.callbacks.forEach(cb => {
              cb()
          })
    },
    },

})
